@mixin display-flex() {
    display:flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
}
@mixin align-self() {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
}
$white: #ffffff;
.headerContainer {
    background: $white;
    padding: 0px 30px 0 0;
    height: 68px;
    .headerContent {
        @include display-flex();
    }
    .logo {
       width: 90px;
       padding: 15px 25px;
       border-right: 1px solid #ebedf8;
    }
    .headerContentRight {
        width: calc(100% - 90px);
        margin: auto 0 auto auto;
        text-align: right;
    }
    .userName {
        margin-left: 10px;
        font-weight: 600;
    }
}