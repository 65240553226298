.addDriverText {
  color: #2e384d;
  font-size: 18px;
  margin: 0;
  padding: 0 0 5px;
}
.addDriverSection {
  background: #fff;
  padding: 30px 25px 5px;
  position: relative;
  text-align: center;
  .editProfile {
    width: 100px;
    // height: 14px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #004dff;
    position: absolute;
    left: 25px;
    top: 20px;
    text-align: left;
    cursor: pointer;
  }
  .viewEarnings {
    width: 100px;
    // height: 14px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #004dff;
    position: absolute;
    left: calc(100% - 115px);
    top: 20px;
    text-align: left;
    cursor: pointer;
  }
  .goBack {
    width: 100px;
    // height: 14px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #004dff;
    position: absolute;
    left: 25px;
    top: 20px;
    text-align: left;
    cursor: pointer;
  }

  div {
      div {
          line-height: 1.5;
          text-align: left;
          display: block;
          label {
              text-transform: uppercase;
              color: #bfc5d2;
              font-size: 12px;
          }
          input {
              padding: 7px 10px 2px 0;
              border: 0;
              border-radius: 0;
              border-bottom: 1px solid rgba(46, 91, 255, 0.08);
            // border-bottom: none;
              outline: 0;
              height: 25px;
              // color:#8798ad;
              color: #2e384d;
              width: 100%;
              &:focus {
                  outline: 0;
                  box-shadow: none;
              }
              &:hover {
                  // border: none;
              }
          }
          button {
              width: 100%;
          }
      }
  }
  .avatarBlock {
    text-align: center;
    padding: 20px 0 0px;

  }

}
.statusbar {
  padding-top: 5px;
  span {
    display: inline-block;
    width: calc(100% - 32px);
    position: relative;
    top: -3.5px;
  }
}
.close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #2e384d;
}


