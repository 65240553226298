.addDriverText {
      color: #2e384d;
      font-size: 18px;
      margin: 0;
      padding: 0 0 5px;
  }
  .addDriverSection {
      background: #fff;
      padding: 30px 25px 5px;
      position: relative;
      text-align: center;
      div {
          div {
              line-height: 1.5;
              text-align: left;
              display: block;
              label {
                  text-transform: uppercase;
                  color: #bfc5d2;
                  font-size: 12px;
              }
              input {
                  padding: 7px 0 2px;
                  border: 0;
                  border-radius: 0;
                  border-bottom: 1px solid rgba(46, 91, 255, 0.08);
                  outline: 0;
                  height: 25px;
                //   color:#8798ad;
                color: #2e384d;
                width: 100%;
                  &:focus {
                      outline: 0;
                      box-shadow: none;
                  }
              }
              button {
                  width: 100%;
              }
          }
      }
  }
  .login-form {
      padding-top: 20px;
  }
  .statusBar {
      padding-top: 5px;
      span {
          width: calc(100% - 32px);
          display: inline-block;
            position: relative;
            top: -3.5px;
      }
  }
  .close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #2e384d;
  }

