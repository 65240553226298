@mixin display-flex() {
    display:flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
}
@mixin align-self() {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
}
.dashboardContainer {
    min-height: 100vh;
    width: 100%;
    .dashboardContent {
        min-height: calc(100vh - 68px);
        width: 100%;
        @include display-flex();
    }
    .dashboardBody {
        display: inline-block;
        width: calc(100% - 90px);
        padding: 50px 30px 35px;
        background: #f3f7fd;
    }
    .searchAndTotal {
       @include display-flex();
       margin-bottom: 30px;
       h3 {
           width: calc(100% - 200px - 100px);
           margin: auto;
           font-size: 25px;
           padding-right: 16px;
           @include align-self();
           color: #2e384d;
           span {
               font-size: 14px;
               vertical-align: middle;
               color: #8798ad;
               padding-left: 15px;
           }
       }
       input {
           height: 40px;
           border: solid 1px #2e5bff;
       }
    }
    .addNewBtn {
        width: 100px;
        color: #fff;
        background: #2e5bff;
        height: 40px;
        border-radius: 5px;
        border: 0;
        outline: 0;
        cursor: pointer;
        margin-left: 16px;
    }
    table {
        border-collapse: collapse !important;
    }

    tbody > tr {
        background: #fff;
        border-bottom: solid 7px rgba(46, 91, 255, 0.08);
        &:hover td {
            background: transparent !important;
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected {
        background: red !important;
    }

    thead > tr > th, tbody > tr > td {
        padding: 12px 0 !important;
        text-align: center;
        span {
            vertical-align: middle;
            display: inline-block;
            font-size: 15px;
            text-transform: capitalize;
        }
    }
    thead > tr > th {
        background: #f3f7fd;
        padding: 5px 0 !important;
        span {
            text-transform: uppercase;
            font-size: 12px;
            color: #bfc5d2;
            letter-spacing: 1.12px;
            font-weight: 600;
        }
    }
    tbody > tr > td {
        color: #2e384d;
        border-bottom: 0 !important;
        &:nth-child(2) {
            text-align: left;
        }
        &:nth-child(3) {
            color: #8798ad;
        }
        &:last-child {
            color: #17a74c;
        }
    }
    .addDriverText {
        color: #2e384d;
        font-size: 18px;
        margin: 0;
        padding: 0 0 5px;
    }
    .addDriverSection {
        background: #fff;
        padding: 30px 25px 5px;
        position: relative;
        text-align: center;
        div {
            div {
                line-height: 1.5;
                text-align: left;
                display: block;
                label {
                    text-transform: uppercase;
                    color: #bfc5d2;
                }
                input {
                    padding: 7px 0 2px;
                    border: 0;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(46, 91, 255, 0.08);
                    outline: 0;
                    height: 25px;
                    color:#8798ad;
                    &:focus {
                        outline: 0;
                        box-shadow: none;
                    }
                }
                button {
                    width: 100%;
                }
            }
        }
    }
    .activeSection {
        // @include display-flex();
        display: flex !important;
        .actionSectionText {
            width: calc(100% - 70px);
            @include align-self();
            color: #17a74c;
        }
        button {
            width: 70px !important;
        }
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #2e384d;
    }

    .driversTableRow {
        padding-left: 10px;
        cursor: pointer;
    }

}
