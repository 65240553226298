.SideBarContainer {
    width: 90px;
    display: inline-block;
    min-height: calc(100vh - 68px);
    background: #fff;
    border: 1px solid #ebedf8;
    border-top: 0;
    ul {
        border-right: 0;
        padding-top: 30px;
        li {
            padding: 8px 0 !important;
            height: auto !important;
            background: transparent !important;
            text-align: center;
            margin: 20px 0 !important;
            i {
                font-size: 25px !important;
                margin-right: 0 !important;
            }
        }
    }
}