
body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f5f7ff99;
    background-image: url("./assets/img.png");
    background-repeat: repeat;
    background-size: auto;
    bottom: 0;
    font-family: sans-serif;
    /* margin: 0 0 3rem; */
}

.appBody{
    padding-bottom: 2.5rem;
    padding-top: 80px

}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
    text-decoration: none;
}
.link {
    color: #ffffff;
    cursor: pointer;
    font-size: 18px;
}
.link:hover {
    color: #00a1c9;
}
.link-file {
    color: black;
    cursor: pointer;
    font-size: 18px;
}
.link-file:hover {
    color: orange;
}

/* search css  */

.search-and-back-span {
    display: block;
    text-align: center;
    max-width: 600px;
    margin: auto;
    padding: 25px;
}
.searchContent {
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
    width: 100%;
}
.backButton {
    color: white;
    cursor: pointer;
    font-size: 20px;
    margin: 10px;
    background: #4267b2;
    padding: 14px 28px;
    border-radius: 5px;
    width: 75px;
    height: 55px;
    display: -webkit-box; 
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    display: flex;
}
.backButton i {
    margin: auto;
}
.backButton:hover {
        color: orange;
}
.search {
    width: calc(100% - 75px);
    /* height: 55px; */
}
.input{
    border: 2px solid #007eb9;
    font-size: 20px;
    width: 100%;
    height: 55px;
    display: inline;
    padding: 14px 28px;
    margin: 10px;
    border-radius: 5px;
    outline: 0;
}



ul.breadcrumb {
    cursor: pointer;
    padding: 10px 10px 10px 50px;
    list-style: none;
    background-color: transparent;
    margin: 0px;
    border-bottom: 10px #4267b2;
}

ul.breadcrumb li {
    font-family: inherit;
     display: inline;
     font-size: 22px;
 }
ul.breadcrumb li:hover {
    color: #4267b2;
}
ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
}
ul.breadcrumb li a {
    color: #4267b2;
    text-decoration: none;
}
ul.breadcrumb li a:hover {
    color: #4267b2;
    text-decoration: underline;
}
.folder-image{
    max-width:10%;
    height:auto;
}
ul.folder-list-class{
    text-align: center;
    margin-top:100px

}
ul.folder-list-class li {
    display: inline;
    margin-right: 300px;
    color: white;
}
ul.folder-list-class li:hover {
    color: #ff9900;
}


.headerView {
    display: flex;
    height: 64px;
}
.logoText {
    width: 120px;
}
.logoText h3 {
    color: rgba(255, 255, 255, 0.65);
    font-size: 20px;
    font-weight: 600; 
}
.logoText h3:hover {
    color: rgba(255, 255, 255, 1); 
}
.headerView ul.ant-menu {
   margin: auto 0 auto auto;
   /* width: calc(100% - 100px); */
}
.file-list-class{
    margin-left: 400px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.folder-tile{

    cursor: pointer;
    display:inline-block;
    background: #4267b2;
    width: 280px;
    padding-top: 50px;
    border-radius: 10px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    /* box-shadow: 5px 5px #4267b2; */
    word-wrap: break-word;
    text-align: center;
}

.dashboard-tile{
    cursor: pointer;
    display:inline;
    background: #4267b2;
    padding-top: 50px;
    border-radius: 10px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 5px 5px #4267b2
}
.footer{
    text-align: center;
    position: fixed;
    margin-top: 40px;
    bottom: 0;
    width: 100%;
    height: 3rem;
    background: #4267b2;
    /*background-image: url("./assets/think-sabio.png");*/

}
.footer-image{
    height: 50px;
    padding-top: 15px;
    padding-bottom: 10px;
}

.header{
    text-align: center;
    top: 0;
    width: 100%;
    background: transparent;
}

.header-image{
    height: 70px;
    padding-top: 15px;
    margin-top: 15px;
}

.loader {
    margin-top: 10px;
    margin-left: 60px;
    border: 6px solid #f3f3f3; /* Light grey */
    border-top: 6px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/* .flodersViewContainer {
    height: calc(100% - 64px);
} */
.folder{
    box-sizing: border-box;
    color: white;

}
.folder:hover{

    color: orange;

}
.folder-list-container .folder{
    width: 280px;
    /* margin: 40px auto 20px; */
    display: inline-block;
    margin: 0 15px 30px;

}

.folder-list-container{
    /* max-width: 1000px;
    margin: 40px auto 0 auto;
    display: flex; */
    padding: 40px 8% 20px;
    text-align: center;

}
.folder-list-container-grid{
    margin: 40px 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-column-gap: 40px;
    grid-row-gap: 20px;
}
#tnc{
    text-align: center;
    margin-top: -3rem;
}
#show-tnc-button{
    -webkit-appearance: none;
    text-rendering: auto;
    color: #ff9900;
    outline: none;
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: pointer;
    box-sizing: border-box;
    margin: 0em;
    font: inherit;
    border: none;
    background: transparent;

}
.Modal {
    position: absolute;
    top: 70px;
    left: 140px;
    right: 140px;
    bottom: 80px;
    background-color: #fafafa;
    margin: auto;
    width: 600px;
    overflow-y: auto
;
}

.Modal:focus{
    outline: 0;
}
.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(173, 173, 171, 0.26);
}
.parent-modal{
    left: 50%;
}

.agreement-block{
    padding: 30px 40px;
    position:relative;
}
.agreement-block p {
    word-spacing: 0.5px;
    letter-spacing: 0.6px;
    line-height: 1.2;
    word-break: break-word;
}
.agreement-block p:nth-child(3){
    margin-bottom: 50px;
}
.cancel-button-section{

    position: fixed;
    bottom: 20px;
    text-align: center;
    background: #fafafa;
    width: 600px;
    margin-right: 600px;

}

.cancel-button-section .cancel-tnc-block{
    float: left;
    height: 40px;
    width: auto;
    font-size: 16px;
    outline: 0px;
    cursor:pointer;
    background-color: #fafafa;
    color: #ff9900;
    border: none;
    padding-left: 15px   ;
    padding-right: 15px;
    margin: 10px 50px;
}


.agree-tnc-block{
    height: 40px;
    width: auto;
    font-size: 16px;
    outline: 0px;
    cursor:pointer;
    background-color: #ff9900;
    color: white;
    border: none;
    padding-left: 15px   ;
    padding-right: 15px;
    margin: 10px 50px;
    float: right;
}
@media only screen and (max-width: 767px) {
    /* .folder-list-container{
        max-width: 80%;
        margin: 40px auto 0 auto;
        display: flex;
        flex-direction: column;

    } */

    /* .folder-tile{

        width: 200px;
        height: 50px;

    } */
    .ant-layout-header {
        padding: 0 30px;
    }
    ul.breadcrumb {
        padding: 0 30px;
    }
    ul.breadcrumb li {
        font-size: 15px;
    }
    .folder-list-container .folder {
        margin: 0 0 20px
    }
    .file-list-class{
        max-width: 80%;
        margin: 40px auto 0 auto;
        display: flex;
        flex-direction: column;
    }
    .file-tile{
        font-size: 15px;
        width: 100%;
        word-break: break-all;
        margin-bottom: 10px;
        height: 50px !important;

    }
    .file-list-class li
    {
        line-height: 25px !important;
    }
    #tnc{
        margin-top:30px;
    }

    .Modal {
        position: absolute;
        top: 70px;
        left: 10px;
        right: 10px;
        bottom: 40px;
        background-color: #fafafa;
        margin: auto;
        max-width: 90%;
        overflow-y: auto
    ;
    }

    .Modal:focus{
        outline: 0;
    }
    .Overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(173, 173, 171, 0.26);
    }
    .agreement-block{
        padding: 10px 19px;
        position:relative;
    }
    .agreement-block p {
        font-size: 14px;
        word-spacing: 0.5px;
        letter-spacing: 0.6px;
        line-height: 1.2;
        word-break: break-word;
    }
    .agreement-block p:nth-child(3){
        margin-bottom: 50px;
    }
    .cancel-button-section{

        position: fixed;
        bottom: 0px;
        text-align: center;
        left: 0;
        right: 0;
        padding-top: 10px;
        padding-bottom: 15px;
        width: auto;
        margin-right: 0px;

    }
    .cancel-button-section button {
        width: auto;
        font-size: 16px;
        outline: 0px;
        cursor:pointer;
        border: none;
        padding-top: 5px;
        padding-bottom: 7px;
        height: auto;


    }

}
@media (min-width: 526px) and (max-width: 767px) {
    .folder-list-container {
       padding: 40px 5% 20px;
    }
    .folder-list-container .folder {
        margin: 0 15px 30px;
        width: 250px;
    }
    .folder-tile {
        width: 250px;
    }
}
@media (max-width: 525px) {
    .ant-layout-header {
        padding: 0 30px;
    }
    .headerView ul.ant-menu li.userName {
       display: none;
       opacity: 0;
    }
    .folder-list-container {
        padding: 40px 30px 20px;
    }
    .folder-list-container .folder {
        width: 100%;
    }
    .folder-tile {
        width: 100%;
    }

}

.ant-btn-primary {
    background: #4267b2;
    border-color: #4267b2   
}
.ant-btn-primary, .ant-btn-primary:focus, .ant-btn-primary:hover{
    background: #4267b2;
    border-color: #4267b2  
}

.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
    background: #4267b2;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
    background-color: #4267b2
}