@mixin display-flex() {
    display:flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
}
@mixin align-self() {
    -ms-align-self: center;
    -webkit-align-self: center;
    align-self: center;
}
.driverRequestContainer {
    min-height: 100vh;
    width: 100%;
    .driverRequestContent {
        min-height: calc(100vh - 68px);
        width: 100%;
        @include display-flex();
    }
    .driverRequestBody {
        display: inline-block;
        width: calc(100% - 90px);
        padding: 50px 30px 35px;
        background: #f3f7fd;
    }
    .headerSectionBox {
        @include display-flex();
    }
    .driverHeaderText {
        width: calc(100% - 70px);
        font-size: 25px;
    }
    table {
        border-collapse: collapse !important;
    }

    tbody > tr {
        background: #fff;
        border-bottom: solid 7px rgba(46, 91, 255, 0.08);
        &:hover td {
            background: transparent !important;
        }
    }
    .ant-table-tbody > tr.ant-table-row-selected {
        background: red !important;
    }

    thead > tr > th, tbody > tr > td {
        padding: 12px 0 !important;
        // text-align: left;
        text-align: center;
        span {
            vertical-align: middle;
            display: inline-block;
            font-size: 15px;
            text-transform: capitalize;
        }
    }
    thead > tr > th {
        background: #f3f7fd;
        padding: 5px 0 !important;
        span {
            text-transform: uppercase;
            font-size: 12px;
            color: #bfc5d2;
            letter-spacing: 1.12px;
            font-weight: 600;
        }
    }
    tbody > tr > td {
        color: #2e384d;
        border-bottom: 0 !important;
        text-align: center;
        // &:nth-child(2) {
        //     text-align: left;
        // }
    }
}