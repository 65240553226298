.button {
  height: 28px;
  line-height: 28px !important;
  margin-right: 0.5rem;
  font-size: 80%;
  font-weight: 600;
}

.pending {
  margin-left: auto;
  border-color: #F3C473;
  color: #F3C473;
  background-color: #FFF7E6;
  text-transform: uppercase;
}