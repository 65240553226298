.loginContainer {
    label {
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        &::before {
            color: #000000;
        }
    }
    span:first-child {
        position: relative;
    }
    span {
        i {
            margin-top: -3px;
        }
    }
    input {
       border: 0;
       border-bottom: 1px solid #dee3e9;
       border-radius: 0;
       outline: 0;
       padding-left: 0;
       width: 100%;
       margin-bottom: 5px;
       opacity: 0.65;
       height: 36px;
       font-size: 16px;
       letter-spacing: 0.5px;
       &:focus,&:hover {
           outline: 0;
           -webkit-box-shadow: none;
           box-shadow: none;
       }
    }
    .forgotPasswordLink {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 600;
        position: absolute;
        right: 30px;
        background: #fff;
        padding: 0 10px;
        top: 0px;
    }
}
@media (min-width: 310px) and (max-width: 768px) {
    .loginContainer {
        label {
            font-size: 13px;
        }
        input {
            font-size: 15px;
        }
        .forgotPasswordLink {
            position: static;
            padding: 0;
        }
    }
}