@mixin display-flex() {
    display:flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display:-webkit-flex;
}
@mixin align-self() {
    -ms-align-self: center;
    align-self: center;
}
$white: #ffffff;
.loginMainSection {
    background: $white;
    width: 100%;
    height: 100vh;
    .loginMainContent {
        @include display-flex();
        width: 100%;
        height: 100%;
    }
    .loginMainLeft {
        width: 45%;
        padding: 50px 65px 50px 100px;
        h3 {
            font-size: 25px;
            margin: 0 0 20px;
            font-weight: 300;
        }
        a {
            color: #ff6801;
        }
    }
    .loginMainRight {
        width: calc(100% - 45%);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .mainLogo {
        width: 74px;
        height: 110px;
        object-fit: cover;
    }
    .loginFormSection {
       @include display-flex();
       height: calc(100% - 110px);
    }
    .loginFormSectionContent {
       @include align-self();
       margin: auto 0;
       width: 100%;
       p {
         font-size: 13px;
         margin: 0;
         padding-top: 70px;
       }
    }
}
@media (min-width: 768px) and (max-width: 992px) {
    .loginMainSection {
        .loginMainLeft {
            padding: 50px 30px;
        }
    }
}
@media (min-width: 310px) and (max-width: 767px) {
    .loginMainSection {
        min-height: 530px;
        .loginMainLeft {
            padding: 30px 15px; 
            width: 100%;
            h3 {
                margin: 20px 0;
            }
        }
        .loginMainRight {
           display: none;
           opacity: 0;
        }
        .loginFormSectionContent {
            p {
                padding-top: 15px;
            }
        }
    }
}