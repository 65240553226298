@mixin display-flex() {
  display:flex;
  display:-webkit-box;
  display:-moz-flex;
  display:-moz-box;
  display:-ms-flexbox;
  display:-webkit-flex;
}
@mixin align-self() {
  -ms-align-self: center;
  align-self: center;
}
.forgetPasswordContainer {
  height: 100vh;
  background: #ffffff;
  width: 100%;
  .forgetPasswordContent {
      @include display-flex();
      height: 100%;
  }
  .forgetPasswordLeft {
    width: 45%;
    padding: 50px 65px 50px 100px;
  }
  .forgetPasswordLeftContent {
    @include display-flex();
    height: calc(100% - 100px);
    align-items: center;
    width: 100%;
    h3 {
      font-size: 25px;
      max-width: 300px;
      margin: 20px 0;
    }
    form {
      width: 100%;
    }
    input {
       width: 100%;
    }
  }
  .forgetPasswordRight {
    width: calc(100% - 45%);
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
   .forgetPasswordContainer {
      .forgetPasswordLeft {
        padding: 50px 30px;
      }
   }
}
@media (min-width: 310px) and (max-width: 767px) {
   .forgetPasswordContainer {
      min-height: 400px;
      .forgetPasswordLeft {
        padding: 30px 15px; 
            width: 100%;
            h3 {
                margin: 20px 0;
                font-size: 20px;
            }
      }
      .forgetPasswordRight {
        display: none;
        opacity: 0;
      }
   }
}
